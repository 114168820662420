@keyframes scrolling {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

#music {
    width: 100%;
    display: grid; 
    grid-template-columns: 10% 20% 20% 40% 10%; 
    #nowPlaying {
        display: grid;
        grid-template-columns: 30% 70%; 
        gap: 15px;
        align-items: center;

        overflow: hidden;

        * {
            white-space: pre;
        }


        #songNameContainer {
            overflow: hidden;
            margin-left: 10px;
        }
        #songName {
            display: inline-block;
            transform: translateX(0%);
            // Thanks to https://blog.hubspot.com/website/scrolling-text-css
            animation-name: scrolling;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: steps(40, jump-both);

        }
    }
    
}