@import "styles/variable";
@import "styles/fonts";
@import "styles/elements";
@import "styles/messages";
@import "styles/music";


html, body {
    margin: 0;
    padding: 0;
}

body {
    background-color: $background;
    background-image: url("../assets/background.gif");

    font-family: 'League Spartan', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: large;
}


header, main {
    text-align: center;
}

header {
    font-size: larger;
}

body > * {
    width: 80%;
    margin: auto;

    box-sizing: border-box;
    padding: 50px calc(30px + 15px);
    margin-bottom: 20px;
    
    color: $color;
    background-color: $primary;

    outline-style: groove;
    outline-width: 4px;
    outline-color: $background;
    outline-offset: -15px;
}

@keyframes typewriter {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.typewriter {
    width: 0;
    white-space: nowrap;
    overflow: hidden;

    // Thanks to https://css-tricks.com/snippets/css/typewriter-effect/ !
    &.auto {
        margin: auto;

        animation-name: typewriter;
        animation-duration: 4s;
        animation-timing-function: steps(40, jump-both);
        animation-fill-mode: forwards;
    }
}
