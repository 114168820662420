@font-face {
  font-family: League Spartan;
  src: url("LeagueSpartan-Regular.609c120f.woff2") format("woff2");
}

@font-face {
  font-family: Anthony;
  src: url("Anthony.abe4e9cf.woff2") format("woff2");
}

h1, h2, h3, h4, h5, h6 {
  font-family: Anthony, Courier New, Courier, monospace;
}

a:link, a:visited {
  color: #fff;
}

a:hover {
  color: #c589e8;
}

a:active {
  color: #fff;
}

hr {
  border-color: #1d0042;
}

button, .range {
  color: #fff;
  outline-offset: -5px;
  cursor: pointer;
  background-color: #1d0042;
  border: none;
  outline: 2px groove #6c1e99;
  padding: 12px;
}

.range {
  justify-content: center;
  align-items: center;
  display: flex;
}

.range input[type="range"] {
  width: 90%;
}

@keyframes fadein {
  from {
    color: #6c1e99;
    background-color: #6c1e99;
  }

  to {
    color: #fff;
    background-color: #1d0042;
  }
}

#messageSelect {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 25px;
  display: flex;
}

#messageSelect button {
  white-space: pre;
  text-align: center;
  color: #6c1e99;
  background-color: #6c1e99;
  width: 200px;
  animation-name: fadein;
  animation-duration: 2s;
  animation-timing-function: steps(40, jump-both);
  animation-delay: 5s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

#messageSelect button:first-of-type {
  animation-delay: 2s;
}

#messageSelect button:nth-of-type(2) {
  animation-delay: 3.5s;
}

.message {
  opacity: 0;
  width: 80%;
  margin: auto auto 20px;
  display: none;
  position: relative;
}

.message img {
  float: right;
  border-top: 2px groove #c589e8;
  border-bottom: 2px groove #c589e8;
  max-height: 200px;
}

.message * {
  overflow: hidden;
}

.message > :not(.info) {
  opacity: 0;
  white-space: initial;
}

.message .close {
  color: #c589e8;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 40px;
}

@keyframes scrolling {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

#music {
  grid-template-columns: 10% 20% 20% 40% 10%;
  width: 100%;
  display: grid;
}

#music #nowPlaying {
  grid-template-columns: 30% 70%;
  align-items: center;
  gap: 15px;
  display: grid;
  overflow: hidden;
}

#music #nowPlaying * {
  white-space: pre;
}

#music #nowPlaying #songNameContainer {
  margin-left: 10px;
  overflow: hidden;
}

#music #nowPlaying #songName {
  animation-name: scrolling;
  animation-duration: 5s;
  animation-timing-function: steps(40, jump-both);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: inline-block;
  transform: translateX(0%);
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #1d0042;
  background-image: url("background.84b3f21b.gif");
  font-family: League Spartan, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
}

header, main {
  text-align: center;
}

header {
  font-size: larger;
}

body > * {
  box-sizing: border-box;
  color: #fff;
  outline-offset: -15px;
  background-color: #6c1e99;
  outline: 4px groove #1d0042;
  width: 80%;
  margin: auto auto 20px;
  padding: 50px 45px;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.typewriter {
  white-space: nowrap;
  width: 0;
  overflow: hidden;
}

.typewriter.auto {
  margin: auto;
  animation-name: typewriter;
  animation-duration: 4s;
  animation-timing-function: steps(40, jump-both);
  animation-fill-mode: forwards;
}

/*# sourceMappingURL=index.6598fe6b.css.map */
