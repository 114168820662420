@keyframes fadein {
    from {
        color: $primary;
        background-color: $primary;
    }
    to {
        color: $color;
        background-color: $secondary;
    }
}

#messageSelect {
    margin-top: 25px;
    
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    button {
        width: 200px;
        overflow: hidden;
        white-space: pre;
        text-align: center;

        color: $primary;
        background-color: $primary;

        animation-name: fadein;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: steps(40, jump-both);
        animation-delay: 5s;  // Default, in case of other messages being added

        &:nth-of-type(1) {
            animation-delay: 2s;
        }
        &:nth-of-type(2) {
            animation-delay: 3.5s;
        }
    }
}


.message {
    display: none;
    opacity: 0;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    position: relative;

    img {
        max-height: 200px;
        float: right;
        $border: 2px $tertiary groove;
        border-bottom: $border;
        border-top: $border;
    }

    * {
        overflow: hidden;
    }
    > :not(.info) {
        opacity: 0;
        white-space: initial;
    }

    .close {
        color: $tertiary;
        right: 40px;
        top: 35px;
        position: absolute;
        cursor: pointer;
    }
}
